<template>
    <div class="view-trip-detail-pdf" v-if="translates && translates[langUrl]">
        <div>
            <div class="view-trip-detail-pdf__trip-info" v-if="translates[langUrl]">
                <div class="view-trip-detail-pdf__main-container">
                    <div v-if="trip.pictures && trip.pictures.length > 0" class="view-trip-detail-pdf__photo-container">
                        <div
                            :style="`background-image: url(${imageSrc(trip.pictures[trip.mainPictureIndex])})`"
                            class="view-trip-detail-pdf__solo-main-photo"
                        ></div>
                    </div>
                    <div class="view-trip-detail-pdf__main-padding">
                        <div class="main-info" style="margin-top: 20px;">
                            <div class="main-info__label-container">
                                <div class="main-info__label">{{ translates[langUrlMainInfo].label_booking[lang] }}</div>
                            </div>
                            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                                <h1 class="main-info__title">{{ trip.name }}</h1>
                            </div>
                            <!-- <div class="main-info__rating">
                                <template v-if="trip.programs">{{ trip.programs.length }} {{ lastDigitToWord(trip.programs.length) }}</template> * 4,5 
                                <img src="../../../assets/StarRating.svg" style="width: 14px; height: 14px; margin: 0 4px 0" /> (677)
                            </div> -->
                            <div v-if="trip.direction && trip.direction[0]" class="main-info__direction">
                                <div class="main-info__direction__item">
                                    {{ translates[langUrlMainInfo].start[lang] }} 
                                    {{ trip.direction[0].country ? trip.direction[0].country.title : '' }}{{ trip.direction[0].state ? (', ' + trip.direction[0].state.title) : '' }}{{ trip.direction[0].city ? (', ' + trip.direction[0].city.title) : '' }}
                                </div>
                                <div class="main-info__direction__item">
                                    {{ translates[langUrlMainInfo].finish[lang] }}
                                    {{ trip.direction[trip.direction.length - 1] ? trip.direction[trip.direction.length - 1].country.title : '' }}<template style="margin-left: 10px">{{ trip.direction[trip.direction.length - 1].state ? (', ' + trip.direction[trip.direction.length - 1].state.title) : '' }}</template>{{ trip.direction[trip.direction.length - 1].city ? (', ' + trip.direction[trip.direction.length - 1].city.title) : '' }}
                                </div>
                            </div>
                        </div>

                        <template v-if="trip.type === TRIP_TYPE.TOUR_BLOGGER">
                            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                <div class="view-trip-detail-pdf__block-name">{{ translates[langUrl].blockName_blogger[lang] }}</div>
                            </div>
                            <Organizer :user="blogger" />
                        </template>
                        <template v-else>
                            <div v-if="!bloggersPage" ref="organizer" class="view-trip-detail-pdf__block-name">{{ translates[langUrl].blockName_expert[lang] }}</div>
                            <Organizer 
                                v-if="!bloggersPage" 
                                :user="trip.organizer && trip.organizer.firstname ? trip.organizer : trip.user" />
                        </template>
                        
                        <div v-if="translates && translates[langUrl]" style="page-break-before: always;">
                            <div class="view-trip-detail-pdf__block-name">{{ translates[langUrl].blockName_mainPlaces[lang] }}</div>
                            <div class="view-trip-detail-pdf__main-places">
                                <template v-for="place in trip.mainPlaces">
                                    <div :key="`mainPlaces-${place._id}`" class="view-trip-detail-pdf__main-places__item">
                                        <div class="view-trip-detail-pdf__main-places__item__picture" :style="`background-image: url(${ imageSrc(place.picture) })`"></div>
                                        <div class="view-trip-detail-pdf__main-places__item__title">{{ place.description }}</div>
                                    </div>
                                </template>
                            </div>

                            <yandexMap
                                v-if="trip.direction"
                                ref="ymap"
                                class="view-trip-detail-pdf__yandexmap"
                                :controls="['zoomControl']"
                                :coords="centerCoordinates"
                                zoom="6"
                            >
                                <template v-for="(direct, i) in trip.direction">
                                    <ymapMarker 
                                        :key="i"
                                        :marker-id="i"
                                        marker-type="Placemark"
                                        :coords="direct.coordinates"
                                    />
                                </template>
                                <ymapMarker
                                    marker-id="1"
                                    marker-type="Polyline"
                                    :coords="polyline"
                                />
                            </yandexMap>

                            <Benefits
                                v-if="trip.benefits && trip.benefits[0] && trip.benefits[0].title && trip.benefits[0].title !== ''"
                                :title="translates[langUrl].blockName_highlights[lang]"
                                :items="trip.benefits"
                                style="page-break-before: always;"
                            />
                            
                            <template v-if="trip.programs && trip.programs.length > 1">
                                <div style="page-break-before: always;" ref="program" class="view-trip-detail-pdf__block-name">{{ translates[langUrl].blockName_tourProgram[lang] }}</div>
                                <TripProgram 
                                    :items="trip.programs" 
                                    :isDesktop="isDesktop"
                                    hide-buttons />
                            </template>

                            <template v-if="trip.workshops && trip.workshops[0] && trip.workshops[0].title && trip.workshops[0].title !== ''">
                                <div class="view-trip-detail-pdf__workshops" style="page-break-before: always;">
                                    <template v-for="(workshop, i) in trip.workshops">
                                        <div :key="'workshop' + i" class="view-trip-detail-pdf__workshops__item" v-if="workshop.title">
                                            <div class="view-trip-detail-pdf__workshops__item__title">
                                                {{ workshop.title }}
                                            </div>
                                            <div class="view-trip-detail-pdf__workshops__item__description">
                                                {{ workshop.description }}
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </template>

                            <template  v-if="(trip.included && trip.included.length > 0 && trip.included[0].title !== '') || (trip.notIncluded && trip.notIncluded.length > 0 && trip.notIncluded[0].title !== '')">
                                <h3 class="view-trip-detail-pdf__block-name"
                                    style="page-break-before: always;"
                                    >{{ translates[langUrl].blockName_advantages[lang] }}</h3>
                                <TripAdvantages 
                                    v-if="trip.included && trip.included.length > 0 && trip.included[0].title !== ''" 
                                    :items="trip.included" 
                                    open-all-items
                                    hide-buttons />
                                <TripAdvantages
                                    v-if="trip.notIncluded && trip.notIncluded.length > 0 && trip.notIncluded[0].title !== ''"
                                    class="notIcluded"
                                    :items="trip.notIncluded"
                                    open-all-items
                                    hide-buttons />
                            </template>

                            <TripGallery
                                style="page-break-before: always;"
                                :items="trip.accommodations"
                                :title="translates[langUrl].blockName_accommodations[lang]"
                                :description="trip.accommodationsDescription"
                                description-lenght="80"
                                class="raiting" />
                            <TripGallery
                                :items="trip.activities"
                                :title="translates[langUrl].blockName_activities[lang]"
                                :description="trip.activitiesDescription"
                                description-lenght="80" />
                            <TripGallery
                                :items="trip.transports"
                                :title="translates[langUrl].blockName_transports[lang]"
                                :description="trip.transportsDescription"
                                description-lenght="80" />
                        </div>
                        
                        <template v-if="photos && photos.lenght > 0">
                            <div ref="galery" class="view-trip-detail-pdf__block-name" style="page-break-before: always;">
                                {{ translates[langUrl].blockName_photos[lang] }}
                            </div>
                            <div style="max-width: 1040px; overflow: hidden; border-radius: 10px;">
                                <WaterfallGrid
                                    v-if="photos && photos.lenght > 0"
                                    :photos="photos"
                                />
                            </div>
                        </template>
                        
                        <template v-if="trip.questions && trip.questions.length > 0 && trip.questions[0].question !== ''">
                            <h3 style="page-break-before: always;" class="view-trip-detail-pdf__block-name">{{ translates[langUrl].blockName_questions[lang] }}</h3>
                            <TripQuestions 
                                :items="trip.questions" 
                                open-all-items
                            />
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseButton from '../../components/common/BaseButton.vue';
    import TripAdvantages from '../../components/common/trip/Advantages.vue';
    import TripQuestions from '../../components/common/trip/Questions.vue';
    import TripGallery from '../../components/common/trip/Gallery.vue';
    import Slider from '../../components/common/trip/Slider.vue';
    import IndividualFormat from '../../components/common/trip/IndividualFormat.vue';
    import Reviews from '../../components/common/trip/Reviews.vue';
    import MainInfo from '../../components/common/trip/MainInfo.vue';
    import BookingBlock from '../../components/common/trip/BookingBlock.vue';
    import Calculator from '../../components/common/trip/Calculator.vue';
    import Benefits from '../../components/common/trip/Benefits.vue';
    import DesktopArrivals from '../../components/common/trip/DesktopArrivals.vue';
    import Arrivals from '../../components/common/trip/Arrivals.vue';
    import MobilePreview from '../../components/common/trip/PreviewCard.vue';
    import WaterfallGrid from '../../components/common/WaterfallGrid.vue';
    import Chat from '../../components/common/trip/Chat.vue';
    import PopupConsultation from '../../components/common/PopupConsultation.vue';
    import PopupNoPresentation from '../../components/common/PopupNoPresentation.vue';
    import DetailMainBlock from '../../components/common/trip/DetailMainBlock.vue';

    import TripProgram from '../../components/common/trip/Program.vue';
    import DesktopGallery from '../../components/common/trip/DesktopGallery.vue';
    import Organizer from '../../components/common/trip/Organizer.vue';

    import store from '@/store';
    import moment from '@/plugins/moment';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { mapState } from 'vuex';
    import { imageSrc, SplideSlideCounter, isMobile } from '@/helpers';
    import { yandexMap, ymapMarker } from 'vue-yandex-maps';
    import { TRIP_STATUS, TRIP_TYPE, FEEDBACK_TYPE } from '@/vars';
    

    export default {
        name: 'Detail',
        metaInfo() {
            return {
                title: this.trip ? this.trip.name : ''
            }
        },
        components: {
            TripProgram,
            DesktopGallery,
            Organizer,

            BaseButton,
            IndividualFormat,
            Slider,
            Reviews,
            TripAdvantages,
            TripQuestions,
            MainInfo,
            BookingBlock,
            Calculator,
            Benefits,
            Arrivals,
            DesktopArrivals,
            MobilePreview,
            Splide,
            SplideSlide,
            WaterfallGrid,
            yandexMap,
            ymapMarker,
            TripGallery,
            Chat,
            PopupConsultation,
            PopupNoPresentation,
            DetailMainBlock
        },
        data: () => ({
            TRIP_TYPE,
            FEEDBACK_TYPE,
            imageSrc,
            isDesktop: false,
            hide: true,
            tripSidebarFixed: false,
            tripSidebarAbsolute: false,
            tripMenuFixed: false,
            tripMenuAbsolute: false,
            selectedMonth: null,
            lastMove: null,
            activeArrivalIndex: 0,
            tourSplideCounter: 1,
            placesSplideCounter: 1,
            mainPagesSplideCounter: 1,
            popupConsultation: false,
            popupParticipation: false,
            PopupNoPresentation: false,
            feedback: {
                trip: null,
                name: '',
                phone: '',
                email: '',
                comment: '',
                link: '',
            },
            secondsLeft: 0,
            intervalId: undefined,
            bloggersPage: false,
            individualText: 'Заказать Индивидуальный тур',
            langUrl: '/trip/name',
            langUrlMainInfo: 'mainInfo'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('trips', {
                trips: state => state.entities,
                trip: state => state.entity
            }),
            ...mapState('auth', ['user']),
            ...mapState('reviews', {
                reviews: state => state.entities
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            photos() {
                return this.trip.photos? this.trip.photos.map(item => item.picture) : [] || []
            },
            filteredtrips() {
                return this.trips.filter(item => {
                    if (!item.code || (this.trip.code && this.trip.code.toString() === item.code.toString())) {
                        return false;
                    }
                    return true;
                });
            },
            options () {
                return {
                    offset: 100
                }
            },
            filteredArrivals() {
                const now = new Date();
                let arrivals = this.trip.arrivals || null;
                if(arrivals) {
                    arrivals = arrivals.filter(arrival => {
                        return new Date(arrival.start).getTime() > now.getTime();
                    });
                    arrivals = arrivals.sort((arrivalA, arrivalB) => {
                        return new Date(arrivalA.start).getTime() - new Date(arrivalB.start).getTime();
                    });
                    if(this.selectedMonth) {
                        arrivals = arrivals.filter(arrival => {
                            return moment(arrival.start).format('YYYY-MM-01') === this.selectedMonth;
                        });
                    } else {
                        arrivals = arrivals.slice(0, 3);
                    }
                }
                return arrivals || [];
            },
            filteredArrivalsMonths() {
                const now = new Date();
                const months = [];
                let arrivals = this.trip.arrivals || null;
                if(arrivals) {
                    arrivals = arrivals.filter(arrival => {
                        return new Date(arrival.start).getTime() > now.getTime();
                    });
                    for(const arrival of arrivals) {
                        if(months.indexOf(moment(arrival.start).format('YYYY-MM-01')) < 0) {
                            months.push(moment(arrival.start).format('YYYY-MM-01'));
                        }
                    }
                }   
                return months || [];
            },
            polyline() {
                return this.trip.direction ? this.trip.direction.map(item => item.coordinates) : [];
            },
            centerCoordinates() {
                return this.trip.direction && this.trip.direction.length ? [
                    this.trip.direction[0] ? this.trip.direction.reduce((sum, item) => {
                        return sum + +item.coordinates[0]
                    }, 0) / this.trip.direction.length : 55.749451,
                    this.trip.direction[0] ? this.trip.direction.reduce((sum, item) => {
                        return sum + +item.coordinates[1]
                    }, 0) / this.trip.direction.length : 37.542824,
                ] : [];
            },
            blogger() {
                if(this.trip.type === TRIP_TYPE.TOUR_BLOGGER && this.trip.bloggerTrips && this.trip.bloggerTrips[0]) {
                    return this.trip.blogger;
                }
                return {};
            },
        },
        async mounted() {
            if (this.$route.name === 'trip-bloggers') {
                this.bloggersPage = true;
            }
            if (this.$route.params.code) {
                store.commit('bookings/CLEAR_ENTITY');
                await store.dispatch('trips/getByCode', { code: this.$route.params.code });
                await store.dispatch('reviews/fetch', { limit: 10, page: 1 });
            }
            await this.getTrips();
            if(this.user._id) {
                await this.getBookings();
            }
            this.handleScroll();
        },
        async updated() {
            if (this.trip.code !== this.$route.params.code) {
                await store.dispatch('trips/getByCode', { code: this.$route.params.code });
                await store.dispatch('reviews/fetch', { limit: 10, page: 1 });
            }
        },
        created () {
            // this.$router.go(0);
            store.commit('bookings/CLEAR_ENTITY');
            window.addEventListener('scroll', this.handleScroll);
            this.tourSplideCounter = SplideSlideCounter(350, 768);
            this.placesSplideCounter = SplideSlideCounter(260, 910, (0.3*innerWidth));
            this.mainPagesSplideCounter = SplideSlideCounter(500, innerWidth);
            window.addEventListener('resize', this.onResize);
            this.onResize();
            this.isDesktop = !isMobile();
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            areYouLogin() {
                if(!this.user._id) {
                    false
                }
            },
            handleScroll() {
                if(this.isDesktop) {
                    // this.tripMenuAbsolute = (this.$refs.tripSidebarStop.getBoundingClientRect().top + this.$refs.tripSidebarStop.clientHeight) <= (this.$refs.tripMenuContainer.clientHeight);
                    // this.tripMenuFixed = !this.tripMenuAbsolute && (this.$refs.tripMenuContainer.getBoundingClientRect().top <= 0);
                    this.tripSidebarAbsolute = (this.$refs.tripSidebarStop.getBoundingClientRect().top + this.$refs.tripSidebarStop.clientHeight) <= (this.$refs.tripSidebar.clientHeight);
                    this.tripSidebarFixed = !this.tripSidebarAbsolute && (this.$refs.tripSidebarContainer.getBoundingClientRect().top <= 0);
                    this.$refs.tripSidebarContainer.click();
                }
            },
            getDate() {
                let date = new Date(this.trip.arrival.start);
            },
            duration(arr) {
                let duration = arr.reduce((duration, currentValue) => {
                    return duration + currentValue.duration
                }, 0)
                return duration
            },
            async tripList () {
                await this.$router.push({ name: 'trip-list' });
            },
            async mainPage () {
                await this.$router.push({ name: 'home' });
            },
            async writeToExpert() {
                if(this.user._id) {
                    this.$root.$emit('tripChatOpen');
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        this.$root.$emit('tripChatOpen');
                    });
                }
            },
            async back() {
                await this.$router.back();
            },
            async favoriteAdd({ id }) {
                if (this.user._id) {
                    await store.dispatch('trips/favoriteAdd', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        await store.dispatch('trips/favoriteAdd', { id });
                        await store.dispatch('auth/fetch');
                        await this.getTrips();
                    });
                }
            },
            async favoriteDelete({ id }) {
                if (this.user._id) {
                    await store.dispatch('trips/favoriteDelete', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        await store.dispatch('trips/favoriteDelete', { id });
                        await store.dispatch('auth/fetch');
                        await this.getTrips();
                    });
                }   
            },
            async setStatus({ id, status }) {
                await store.dispatch('trips/setStatus', { id, status });
                await this.getTrips();
            },
            monthSelected() {
                if(this.selectedMonth === null) {
                    let comming
                    if(this.lang === 'ru') {
                        comming = 'Ближайшие отправления'
                    } else {
                        comming = 'Coming departures'
                    }
                    return comming
                } else {
                    const string = moment(this.selectedMonth).format('MMMM YYYY');
                    return string[0].toUpperCase() + string.substring(1)
                }
            },
            // async goTo(name) {
            //     if(this.user._id) {
            //         await this.$router.push({ name });
            //     } else {
            //         this.authorization();
            //     }
            // },
            authorization() {
                if(!this.user._id) {
                    this.$root.$emit('popupLoginShow');
                }
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            async getTrips() {
                await store.dispatch('trips/fetch', { filter: {
                    ...this.filter,
                    type: TRIP_TYPE.TOUR_LEADER,
                    status: TRIP_STATUS.ACTIVE
                }, sorting: this.sorting });
            },
            async getBookings() {
                await store.dispatch( 'bookings/fetch', {} );
            },
            async sendFeedback(type) {
                this.feedback = {
                    trip: this.trip._id,
                    name: this.user.firstname,
                    phone: this.user.phone,
                    email: this.user.email,
                    type: type,
                    link: document.location.href
                };
                await store.commit('feedbacks/SET_ENTITY', this.feedback);
                await store.dispatch('feedbacks/save');
                await store.commit('feedbacks/CLEAR_ENTITY');
            },
            async consultation() {
                if (this.user._id) {
                    this.sendFeedback(FEEDBACK_TYPE.CONSULTATION)
                    this.openPopupConsultation();
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        this.sendFeedback(FEEDBACK_TYPE.CONSULTATION)
                        this.openPopupConsultation();
                    });
                }
            },
            async participation() {
                if (this.user._id) {
                    await this.sendFeedback(FEEDBACK_TYPE.PARTICIPATION);
                    this.openPopupParticipation();
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        await this.sendFeedback(FEEDBACK_TYPE.PARTICIPATION);
                        this.openPopupParticipation();
                    });
                }
            },
            async individual() {
                if (this.user._id) {
                    await this.sendFeedback(FEEDBACK_TYPE.INDIVIDUAL_TRIP);
                    this.individualText = 'Заявка на индивидуальных тур принята'
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                    this.sendFeedback(FEEDBACK_TYPE.INDIVIDUAL_TRIP);
                    this.individualText = 'Заявка на индивидуальных тур принята'
                    });
                }
            },
            openPopupConsultation() {
                this.popupConsultation = true;
            },
            closePopupConsultation() {
                this.popupConsultation = false;
            },
            openPopupParticipation() {
                this.popupParticipation = true;
            },
            closePopupParticipation() {
                this.popupParticipation = false;
            },
            openPopupNoPresentation() {
                this.PopupNoPresentation = true;
            },
            closePopupNoPresentation() {
                this.PopupNoPresentation = false;
            },
            setActiveArrival(index) {
                this.activeArrivalIndex = index;
            },
            checkArrival(arrival) {
                for(const booking of this.bookings) {
                    if(booking.trip && booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                        return true;
                    }
                }
                return false;
            },
            async payBooking(arrival) {
                if(this.user._id) {
                    this.$root.$emit('payBooking');
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        this.$root.$emit('payBooking');
                    });
                }
            },
        }
    }
</script>

<style lang="scss">
.view-trip-detail-pdf {
    width: 100%;
    background: #FFFFFF;
	margin-bottom: 10px;
	// overflow: hidden;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    padding-bottom: 60px;
    margin: auto;
    max-width: 1260px;
    &__main-padding {
        // display: flex;
        @media all and (max-width:768px) {
            padding:  0 20px;
        }
    }
    @media all and (max-width: 1280px) {
        margin: 0 20px;
    }
    &__trip-info {
        position: relative;
        display: flex;
        flex-direction: row;
        padding-top: 10px;
        @media all and (min-width: 768px) {
            justify-content: space-between;
        }
        @media all and (max-width:768px) {
            padding-top: 0;
        }
    }

    &__main-container {
        position: relative;
        // width: 65%;
        max-width: 768px;
        margin-right: 20px;
        // @media all and (max-width: 768px) {
        //     width: calc(100% - 357px);
        // }
        @media all and (max-width:768px) {
            width: 100%;
            margin-right: 0;
        }
    }
    // &__yandexmap {
    //     width: 100%; 
    //     height: 400px; 
    //     margin-top: 38px;
    //     border-radius: 12px;
    //     overflow: hidden;
    // }
    &__photo-container {
        width: 100%;
        height: 450px;
        border-radius: 10px;
        overflow: hidden;
        @media all and (max-width:768px) {
            border-radius: 0;
        }
    }
    &__solo-main-photo {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @media all and (max-width:768px) {
            position: relative;
            width: 100%;
            // height: 320px;
            &__action {
                position: absolute;
                display: flex;
                height: 32px;
                width: 32px;
                top: 40px;
                background: #FFF;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                    cursor: pointer;
                }
                &_back {
                    left: 20px;
                }
                &_download {
                    right: 64px;
                }
                &_like {
                    right: 20px;
                }
            }
            &__icon {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }
            &__text {
                font-size: 12px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #FFFFFF;
            }
        }
    }
    &__menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        // padding: 0 7px;
        border-bottom: 1px solid #E5E6E9;
        color: #65676E;
        &-fixed {
            width: 75%;
            max-width: 1042px;
            position: fixed;
            top: 0;
            background-color: #fff;
            z-index: 100;
            @media all and (max-width: 768px) {
                width: calc(100% - 357px);
            }
        }
        &-absolute {
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #fff;
            z-index: 100;
        }
        &__item {
            padding: 40px 25px 16px;
            
            &:hover {
                color: #DA0A63;
                cursor: pointer;
                border-bottom: 2px solid #DA0A63;
                margin-bottom:  -2px;
            }
        }
    }
    &__block-name {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #273155;
        margin-top: 30px;
    }
    &__presentation {
        display: flex;
        flex-direction: row;
        width: 100%;
        min-height: 112px;
        padding: 30px 30px 30px 25px;
        background: #F6F7F9;
        border-radius: 12px;
        justify-content: space-between;
        margin-top: 70px;
        @media all and (max-width: 768px) {
            flex-direction: column;
            min-height: 220px;
            background: #F6F7F9;
            border-radius: 12px;
            padding: 16px 24px;
            margin: 40px 0;
        }
        &__text {
            display: flex;
            flex-direction: column;
            font-family: Inter;
            font-style: normal;
            letter-spacing: -0.02em;
            color: #3A4256;
        }
        &__title {
            font-weight: 600;
            font-size: 22px;
            line-height: 140%;
            @media all and (max-width: 768px) {
                font-weight: 600;
                font-size: 22px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 12px;
            }
        }
        &__description {
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            @media all and (max-width: 768px) {
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 24px;
            }
        }
        &__button {
            width: 254px;
            height: 52px;
            @media all and (max-width: 768px) {
                width: 100%;
            }
        }
    }
    &__sidebar {
        // max-width: 350px;
        max-width: 420px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        z-index: 100;
        &__wrap {
            position: relative;
            width: 30%;
        }
        &-fixed {
            position: fixed;
            top: 10px;
            // right: calc(50% - 602px);
            right: calc(50% -699px);
            // @media all and (max-width: 768px) {
            //     right: 40px;
            // }
            @media all and (max-width: 1280px) {
                right: 25px;
                width: 29.8%;
            }
        }
        &-absolute {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    &__similar-tours {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        margin-right: -20px;
        @media all and (max-width: 768px) {
            flex-direction: column;
            margin-right: 0;
        }
    }
    &__similar-tours_splide {
        margin-top: 30px;
        @media all and (max-width: 768px) {
            width: calc(100% - 40px);
        } 
        .splide__slide {
            align-items: center;
        }
    }
    &__offers {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        &__offer {
            display: flex;
            flex-direction: row;
            width: 326px;
            height: 114px;
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 10px;
            margin-right: 30px;
            padding: 30px 15px;
            &__picture {
                min-width: 30px;
                height: 30px;
                background: url(../../assets/Cards.svg) no-repeat;
                background-position: center;
                background-size: cover;
                margin-right: 20px;
            }
            &__text {
                font-size: 14px;
                line-height: 130%;
                letter-spacing: -0.02em;
                color: #3A4256;
            }
            &__button {
                margin-top: 5px;
                color: #D80765;
                cursor: pointer;
            }
        }
        &__secondOffer {
            .view-trip-detail-pdf__offers__offer__picture {
                background: url(../../assets/CardsReverse.svg) no-repeat;
            }
        }
    }
    &__months {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        flex-wrap: wrap;
        gap: 10px;
        @media all and (max-width: 768px) {
            overflow: scroll;
            flex-wrap: nowrap;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-width: 140px;
            height: 70px;
            border: 1px solid #EBEBEB;
            border-radius: 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            cursor: pointer;
            &__month {
                text-transform: capitalize;
            }
            &-active {
                color: #fff; 
                background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%), #FFFFFF;
            }
        }
    }

    &__main-places {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 20px;

        &__item {
            width: 370px;
            padding-bottom: 20px;

            &__picture {
                width: 370px;
                height: 300px;
                background-position: center;
                background-size: cover;
                border-radius: 8px;
            }
            &__title {
                font-weight: 500;
                margin-top: 12px;
                font-size: 20px;
                line-height: 24px;
                color: #3a4256;
            }
        }
    }

    &__gallery{
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        margin-top: 16px;
        padding-bottom: 7px;

        &::-webkit-scrollbar {
            display: none;
        }
        .splide__arrow {
            width: 40px;
            height: 40px;
            opacity: 1;
            padding: 12px;
            top: 40%;
            background: #fff;
        }
        .splide__arrow--prev {
            content: url(../../assets/BeforeArrow.svg);
            opacity: 1;
            left: 0;
        }
        .splide__arrow--next {
            content: url(../../assets/NextArrow.svg);
            right: 0;
        }
    }
    .v-window__prev, .v-window__next {
        background: #fff;
    }
    .mdi-chevron-right::before {
        content: url(../../assets/NextArrow.svg);
        margin: 2px 5px 15px;
        
    }
    .mdi-chevron-left::before {
        content: url(../../assets/BeforeArrow.svg);
        margin: 2px 5px 15px;
    }
    .download-link {
        position: absolute;
        width: 254px;
        height: 50px;
    }
    .slider {
        .splide__arrow {
            top: 50%;
            display: none;

        }
        &:hover {
            .splide__arrow {
                display: flex;
            }
        }
        .splide__arrow--prev {
            left: 18%;
        }
        .splide__arrow--next {
            right: 18%;
        }
    }
    
    .main-info {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        letter-spacing: -0.02em;

        &__direction {
            display: flex;
            flex-direction: column;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: -0.02em;
            color: #3A4256;
            margin-top: 16px;
            margin-bottom: 25px;
            @media all and (min-width: 768px) {
                flex-direction: row;
            }
            &__item{
                font-weight: 500;
                // margin-left: 4px;
            }
        }
    }

    .trip-gallery__title {
        margin-top: 20px;
    }
}
</style>